import React, { useState, useEffect } from 'react';
import styles from "./style.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerLink from "./headerLink";
import Button from "../button/button";
import { BASENAME } from "../../constant";
import { SMTP } from "../../constant"
import {isMobile} from 'react-device-detect';
import { useNavigate } from "react-router-dom";

// import headerLogo from "../../img/logo_milanoauto.png"
// import headerLogo from "../../site_images/svg/Logo_milanoauto.svg"

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";

import arrow from "../../img/arrow-down.png";

import Icon from "../icon/icon";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <img src={arrow} className={styles.chevron} />
            </>
        }
        className={styles.item}
        buttonProps={{
            className: ({ isEnter }) =>
                `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
        }}
        contentProps={{ className: styles.itemContent }}
        panelProps={{ className: styles.itemPanel }}
    />
);


function userAction() {
    window.location.href = BASENAME + "preferiti";
}

export default function Header() {
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerLeft}>
                <img src="../site_images/logo-white.png" width={300} height={41} onClick={() => window.location.href = BASENAME} />
            </div>
            <div className={styles.headerRight}>
                <div className="d-block d-md-none">
                    <Button variation="secondary" icon={["far", "heart"]} style={{ height: 40, width: 40 }} iconSize={15} onClick={() => userAction()} />
                </div>
                <div className="d-none d-md-block">
                    <Button label="Preferiti" variation="secondary" icon={["far", "heart"]} style={{ height: 40, padding: 16 }} iconSize={15} onClick={() => userAction()} />
                </div>
                <div className={styles.menuButton} onClick={() => setMenuOpen(!menuOpen)}>
                    <span>Menu</span>
                    <FontAwesomeIcon icon={menuOpen ? "xmark" : "bars"} className={styles.menuButtonIcon} />
                </div>
            </div>


            <div className={styles.headerMenuMobileContainer} style={{ right: (menuOpen) ? "0%" : "-100%" }}>
                <div className={styles.headerMenuMobile}>

                    <Accordion transition transitionTimeout={250} allowMultiple>
                        <AccordionItem header={"Le Nostre Auto"} initialEntered={isMobile ? false : true}>
                            <div className={styles.menuLink} onClick={() => { navigate('/veicoli', { state: { type: "semestrale" } }); window.location.reload() }}>Semestrale</div>
                            <div className={styles.menuLink} onClick={() => { navigate('/veicoli', { state: { type: "Km 0" } }); window.location.reload() }}>KM 0</div>
                            <div className={styles.menuLink} onClick={() => { navigate('/veicoli', { state: { type: "usato" } }); window.location.reload() }}>Usato</div>
                            <div className={styles.menuLink} onClick={() => { navigate('/veicoli', { state: { type: "nuovo" } }); window.location.reload() }}>Nuovo</div>
                        </AccordionItem>
                        <AccordionItem header={"Noleggio"} initialEntered={isMobile ? false : true}>
                            <div className={styles.menuLink} onClick={() => window.location.href = BASENAME + "noleggio-california"}>California</div>
                            <div className={styles.menuLink} onClick={() => window.location.href = BASENAME + "noleggio-veicoli-commerciali"}>Veicoli commerciali <img src="../site_images/icon/veicoli-commerciali-icon.png" /></div>
                            <div className={styles.menuLink} onClick={() => window.location.href = BASENAME + "noleggio-lungo-termine"}>Auto a lungo termine</div>
                        </AccordionItem>
                        <AccordionItem header={"Assistenza"} initialEntered={isMobile ? false : true}>
                            <div className={styles.menuLink} onClick={() => window.location.href = BASENAME + "service"}>Service</div>
                            <div className={styles.menuLink} onClick={() => window.location.href = BASENAME + "carrozzeria"}>Carrozzeria</div>
                        </AccordionItem>
                    </Accordion>

                    <div className={styles.divider} />

                    <div className={styles.menuLinkBold} onClick={() => window.location.href = BASENAME + "contatti"}>Contatti e sedi</div>
                    <div className={styles.menuLinkBold} style={{ marginTop: 10 }} onClick={() => window.location.href = BASENAME + "chi-siamo"}>Chi siamo</div>
                    {/*<div className={styles.menuLinkBold} style={{ marginTop: 10 }} onClick={() => window.location.href=""}>Lavora con noi</div>*/}

                    <div className={styles.divider} />
                    {/*
                    <div className={styles.infoTitle}>
                        Gruppo Milanoauto s.r.l.
                    </div>
                    */}
                    <div className={styles.infoText} style={{ marginTop: 15 }}>
                        <Icon icon="new_icon_phone.png" width={16} height={16} />
                        <a href={"tel:" + headerLink.phone}>{headerLink.phone}</a>
                    </div>
                    <div className={styles.infoText}>
                        <Icon icon="new_icon_email.png" width={16} height={16} />
                        <a href="mailto:info@gruppomilanoauto.it">info@gruppomilanoauto.it</a>
                    </div>
                    <div className={styles.infoText}>
                        <Icon icon="new_icon_whatsapp.png" width={16} height={16} />
                        <a href="https://wa.me/message/6SDAOF2KJBZNE1">WhatsApp</a>
                    </div>

                    {/*<div className={styles.addressTitle}>
                        Sede di Cologno Monzese
                    </div>*/}
                    <div className={styles.addressValue} style={{marginTop:24}}>
                        <Icon icon="new_icon_location.png" width={16} height={16} />
                        <a target="_blank" href="https://www.google.it/maps/place/Gruppo+Milanoauto+-+Rondinella+Auto+Solutions/@45.5411157,9.2881606,16z/data=!3m1!4b1!4m6!3m5!1s0x4786b8172f93bf05:0x523bdb66497b4b45!8m2!3d45.5411157!4d9.2881606!16s%2Fg%2F1vxvgr5x?entry=ttu">
                            Via per Imbersago 19 20093 Cologno Monzese (MI)
                         </a>
                    </div>

                    {/*<div className={styles.addressTitle}>
                        Sede di Cormano
                    </div>*/}
                    <div className={styles.addressValue}>
                        <Icon icon="new_icon_location.png" width={16} height={16} />
                        <a target="_blank" href="https://www.google.it/maps/place/Gruppo+Milanoauto+-+Viola+%7C+Vendita,+Service,+Carrozzeria/@45.5456349,9.1704051,15z/data=!4m6!3m5!1s0x4786bf2ec968dd75:0x72ac118861b50f8!8m2!3d45.5456161!4d9.1645364!16s%2Fg%2F11jg04k8_7?entry=ttu">
                            Via Pietro Nenni 8 20032 Cormano (MI)
                        </a>
                    </div>

                </div>
            </div>


        </div>
    )

}

