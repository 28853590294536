import React from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import MyCarousel from '../../components/carousel/carousel';

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";



export default function Service() {
    let responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: 1

        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const css = `
    .react-multi-carousel-dot-list {
        margin-bottom:5px;
    }
`

    return (
        <Layout>
            <style>{css}</style>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Service</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: "Home", link: BASENAME },
                        { label: "Assistenza", link: null },
                        { label: "Service", link: null }
                    ]}
                />

                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src="site_images/service_new_logo_mobile.png" className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-5 pe-lg-5 d-none d-lg-block">
                            <img src="./site_images/service_new_logo.png" className={styles.image} />
                        </div>
                        <div className="col-12 col-lg-7 ps-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph}>
                                    La nostra officina di <b>Cormano</b> si prende cura della tua auto per garantire la sicurezza dei tuoi viaggi attraverso tagliandi e varie manutenzioni legate a freni, sospensioni, olio, luci e molto altro.
                                    </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Tagliando</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Servizio revisione</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Pneumatici</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Manutenzione straordinaria</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Diagnostica</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Soccorso stradale</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Vettura sostitutiva</h2>
                                    </div>
                                </div>
                                <div className={styles.paragraph} style={{ marginBottom: 0, marginTop: 20 }}>
                                    <b>Prenota ora</b> un appuntamento
                                </div>
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={"02 2540348"}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={()=>window.open('tel:022540348')}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width: 60 }}
                                        onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={styles.contentContainer} style={{ backgroundColor: "#D8DEE9", marginTop: 56 }}>
                <div className={styles.content}>
                    <div className={"d-block d-md-none"}>
                        <MyCarousel
                            responsive={responsive}
                            arrows={false}
                            showDots={false}
                            showDotsTablet={true}
                            showDotsMobile={true}
                        >
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_preparazione.png" />
                                    <h2>Tagliandi certificati</h2>
                                </div>
                                <p>
                                    Effettuiamo tagliandi certificati Volkswagen, Audi, Seat, Skoda, Cupra e Veicoli Commerciali Volkswagen.
                                <b>Non perdi la tutela della garanzia ed il tagliando viene registrato</b> nella banca dati mondiale del Gruppo Volkswagen.
                                </p>
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_Import-car.png" />
                                    <h2>Trasparenza e garanzia</h2>
                                </div>
                                <p>
                                    Rilasciamo una <b>fattura dettagliata</b> con l’elenco delle attività svolte, il codice dei ricambi e le ore di manodopera.<br />
                                    Sui nostri interventi hai una <b>garanzia di 12 mesi</b>.
                                </p>
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_lens.png" />
                                    <h2>Difficoltà ad individuare il problema?</h2>
                                </div>
                                <p>
                                    Grazie ad <b>una diagnosi elettronica con diversi tester</b> (Odis, Texa, Bosch) e l’esperienza del nostro team, siamo sempre in grado di rilevare la causa del guasto ed intervenire con precisione.
                                    </p>
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_value_2.png" />
                                    <h2>Servizio di ritiro e consegna</h2>
                                </div>
                                <p>
                                    Puoi fare comodamente il tagliando da casa o dall'ufficio, devi solo lasciarci le chiavi.<br />
                                    <b>Ci pensiamo noi a ritirare e riconsegnare l’auto</b> dove desideri. Il servizio è disponibile <b>su richiesta ed entro i 10km</b> dalla nostra sede.                                </p>
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_motore.png" />
                                    <h2>Ripristina l’efficienza del motore</h2>
                                </div>
                                <p>
                                    Con un processo di <b>decarbonizzazione</b> puoi ripristinare la potenza iniziale del tuo motore e migliorare le prestazioni in accelerazione. La pulizia non prevede alcun additivo chimico e riguarda l’impianto di scarico, aspirazione e alimentazione.: riduzione dei <b>consumi,  rumorosità ridotta, minimo più stabile</b>
                                </p>
                            </div>

                        </MyCarousel>

                    </div>
                    <div className="row justify-content-center d-none d-md-flex">
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_preparazione.png" />
                                    <h2>Tagliandi certificati</h2>
                                </div>
                                <p>
                                    Effettuiamo tagliandi certificati Volkswagen, Audi, Seat, Skoda, Cupra e Veicoli Commerciali Volkswagen.
                                <b>Non perdi la tutela della garanzia ed il tagliando viene registrato</b> nella banca dati mondiale del Gruppo Volkswagen.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_Import-car.png" />
                                    <h2>Trasparenza e garanzia</h2>
                                </div>
                                <p>
                                    Rilasciamo una <b>fattura dettagliata</b> con l’elenco delle attività svolte, il codice dei ricambi e le ore di manodopera.<br />
                                    Sui nostri interventi hai una <b>garanzia di 12 mesi</b>.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_lens.png" />
                                    <h2>Difficoltà ad individuare il problema?</h2>
                                </div>
                                <p>
                                    Grazie ad <b>una diagnosi elettronica con diversi tester</b> (Odis, Texa, Bosch) e l’esperienza del nostro team, siamo sempre in grado di rilevare la causa del guasto ed intervenire con precisione.
                                    </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_value_2.png" />
                                    <h2>Servizio di ritiro e consegna</h2>
                                </div>
                                <p>
                                    Puoi fare comodamente il tagliando da casa o dall'ufficio, devi solo lasciarci le chiavi.<br />
                                    <b>Ci pensiamo noi a ritirare e riconsegnare l’auto</b> dove desideri. Il servizio è disponibile <b>su richiesta ed entro i 10km</b> dalla nostra sede.                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/icon_motore.png" />
                                    <h2>Ripristina l’efficienza del motore</h2>
                                </div>
                                <p>
                                    Con un processo di <b>decarbonizzazione</b> puoi ripristinare la potenza iniziale del tuo motore e migliorare le prestazioni in accelerazione. La pulizia non prevede alcun additivo chimico e riguarda l’impianto di scarico, aspirazione e alimentazione.: riduzione dei <b>consumi,  rumorosità ridotta, minimo più stabile</b>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className={styles.contentContainer} style={{marginBottom:156}}>
                <div className={styles.brandSection}>
                    <h2>Ricambi originali di tutte le marche</h2>
                    <div className={styles.brandContainer}>
                        <img src="site_images/brands/bosch.png" />
                        <img src="site_images/brands/brembo.png" />
                        <img src="site_images/brands/castrol.png" />
                        <img src="site_images/brands/continental.png" />
                        <img src="site_images/brands/michelin.png" />
                    </div>
                </div>
            </div>

        </Layout>
    );
}

